var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zy-canvas-ring",style:({ height: `${_vm.height}px` })},[_c('canvas',{ref:"bg_canvas",style:({ width: `${_vm.canvas_width}px`, height: `${_vm.canvas_height}px` }),attrs:{"width":_vm.canvas_width,"height":_vm.canvas_height}}),_c('div',{staticClass:"zy-canvas-ring-center",style:({ width: `${_vm.size}px`, height: `${_vm.size}px` })},[_c('canvas',{ref:"center_canvas",style:({ width: `${_vm.size}px`, height: `${_vm.size}px` }),attrs:{"width":_vm.size,"height":_vm.size}})]),_c('div',{staticClass:"zy-canvas-ring-center"},[_vm._t("default")],2),_c('div',{staticClass:"zy-canvas-ring-text",style:({
      left: '15%',
      top: '15%',
    })},[_c('div',{staticClass:"zy-canvas-ring-text-line",style:({ background: _vm.colors[0], marginRight: '10px' })}),_c('div',{staticClass:"zy-canvas-ring-text-content"},[_c('div',{staticClass:"zy-canvas-ring-text-content-title",style:({ color: _vm.colors[0] })},[_vm._v(" "+_vm._s(_vm.list[0]?.name)+" ")]),_c('div',{staticClass:"zy-canvas-ring-text-content-value"},[_c('div',[_vm._v(_vm._s(_vm.list[0]?.value))]),_c('div',[_vm._v(_vm._s(_vm.list[0]?.unit))])])])]),_c('div',{staticClass:"zy-canvas-ring-text",style:({
      left: '15%',
      top: '60%',
    })},[_c('div',{staticClass:"zy-canvas-ring-text-line",style:({ background: _vm.colors[0], marginRight: '10px' })}),_c('div',{staticClass:"zy-canvas-ring-text-content"},[_c('div',{staticClass:"zy-canvas-ring-text-content-title",style:({ color: _vm.colors[0] })},[_vm._v(" 占比 ")]),_c('div',{staticClass:"zy-canvas-ring-text-content-value"},[_c('div',[_vm._v(_vm._s(_vm.list[0]?.rate))]),_c('div',[_vm._v("%")])])])]),_c('div',{staticClass:"zy-canvas-ring-text",style:({
      left: '70%',
      top: '15%',
    })},[_c('div',{staticClass:"zy-canvas-ring-text-content",staticStyle:{"width":"100px"}},[_c('div',{staticClass:"zy-canvas-ring-text-content-title",style:({ color: _vm.colors[1], textAlign: 'end' })},[_vm._v(" "+_vm._s(_vm.list[1]?.name)+" ")]),_c('div',{staticClass:"zy-canvas-ring-text-content-value",staticStyle:{"justify-content":"flex-end"}},[_c('div',[_vm._v(_vm._s(_vm.list[1]?.value))]),_c('div',[_vm._v(_vm._s(_vm.list[1]?.unit))])])]),_c('div',{staticClass:"zy-canvas-ring-text-line",style:({ background: _vm.colors[1], marginLeft: '10px' })})]),_c('div',{staticClass:"zy-canvas-ring-text",style:({
      left: '70%',
      top: '60%',
    })},[_c('div',{staticClass:"zy-canvas-ring-text-content",staticStyle:{"width":"100px"}},[_c('div',{staticClass:"zy-canvas-ring-text-content-title",style:({ color: _vm.colors[1], textAlign: 'end' })},[_vm._v(" 占比 ")]),_c('div',{staticClass:"zy-canvas-ring-text-content-value",staticStyle:{"justify-content":"flex-end"}},[_c('div',[_vm._v(_vm._s(_vm.list[1]?.rate))]),_c('div',[_vm._v("%")])])]),_c('div',{staticClass:"zy-canvas-ring-text-line",style:({ background: _vm.colors[1], marginLeft: '10px' })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }