<template>
  <div
    class="zy-canvas-ring"
    :style="{ height: `${height}px` }"
  >
    <!-- 背景 -->
    <canvas
      ref="bg_canvas"
      :width="canvas_width"
      :height="canvas_height"
      :style="{ width: `${canvas_width}px`, height: `${canvas_height}px` }"
    ></canvas>
    <!-- echarts图 -->
    <div
      class="zy-canvas-ring-center"
      :style="{ width: `${size}px`, height: `${size}px` }"
    >
      <canvas
        ref="center_canvas"
        :width="size"
        :height="size"
        :style="{ width: `${size}px`, height: `${size}px` }"
      ></canvas>
    </div>
    <!-- 中间文本 -->
    <div class="zy-canvas-ring-center">
      <slot></slot>
    </div>
    <!-- 左上角 -->
    <div
      class="zy-canvas-ring-text"
      :style="{
        left: '15%',
        top: '15%',
      }"
    >
      <div
        class="zy-canvas-ring-text-line"
        :style="{ background: colors[0], marginRight: '10px' }"
      ></div>
      <div class="zy-canvas-ring-text-content">
        <div
          class="zy-canvas-ring-text-content-title"
          :style="{ color: colors[0] }"
        >
          {{ list[0]?.name }}
        </div>
        <div class="zy-canvas-ring-text-content-value">
          <div>{{ list[0]?.value }}</div>
          <div>{{ list[0]?.unit }}</div>
        </div>
      </div>
    </div>
    <!-- 左下角 -->
    <div
      class="zy-canvas-ring-text"
      :style="{
        left: '15%',
        top: '60%',
      }"
    >
      <div
        class="zy-canvas-ring-text-line"
        :style="{ background: colors[0], marginRight: '10px' }"
      ></div>
      <div class="zy-canvas-ring-text-content">
        <div
          class="zy-canvas-ring-text-content-title"
          :style="{ color: colors[0] }"
        >
          占比
        </div>
        <div class="zy-canvas-ring-text-content-value">
          <div>{{ list[0]?.rate }}</div>
          <div>%</div>
        </div>
      </div>
    </div>
    <!-- 右上角 -->
    <div
      class="zy-canvas-ring-text"
      :style="{
        left: '70%',
        top: '15%',
      }"
    >
      <div
        class="zy-canvas-ring-text-content"
        style="width: 100px"
      >
        <div
          class="zy-canvas-ring-text-content-title"
          :style="{ color: colors[1], textAlign: 'end' }"
        >
          {{ list[1]?.name }}
        </div>
        <div
          class="zy-canvas-ring-text-content-value"
          style="justify-content: flex-end"
        >
          <div>{{ list[1]?.value }}</div>
          <div>{{ list[1]?.unit }}</div>
        </div>
      </div>
      <div
        class="zy-canvas-ring-text-line"
        :style="{ background: colors[1], marginLeft: '10px' }"
      ></div>
    </div>
    <!-- 右下角 -->
    <div
      class="zy-canvas-ring-text"
      :style="{
        left: '70%',
        top: '60%',
      }"
    >
      <div
        class="zy-canvas-ring-text-content"
        style="width: 100px"
      >
        <div
          class="zy-canvas-ring-text-content-title"
          :style="{ color: colors[1], textAlign: 'end' }"
        >
          占比
        </div>
        <div
          class="zy-canvas-ring-text-content-value"
          style="justify-content: flex-end"
        >
          <div>{{ list[1]?.rate }}</div>
          <div>%</div>
        </div>
      </div>
      <div
        class="zy-canvas-ring-text-line"
        :style="{ background: colors[1], marginLeft: '10px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      canvas_width: 0,
      canvas_height: 0,
      size: 0,
      myChart: null,
    }
  },
  props: {
    height: {
      type: Number,
      require: true,
    },
    list: {
      type: Array,
      default: () => [],
    },
    radius: {
      type: Array,
      default: () => [70, 90],
    },
    colors: {
      type: Array,
      default: () => ["#63E881", "#FC4A66"],
    },
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val.length) return
        this.$nextTick(() => {
          this.canvas_width = this.$el.clientWidth
          this.canvas_height = this.$el.clientHeight
          this.size = this.canvas_height - 16
          this.initBgCanvas()
          this.initPie()
        })
      },
    },
  },
  computed: {
    option() {
      const option = {
        series: [
          {
            type: "pie",
            radius: [`${this.radius[0]}%`, `${this.radius[1]}%`],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            data: this.list,
          },
        ],
        color: this.colors,
      }
      return option
    },
  },
  methods: {
    initBgCanvas() {
      this.$nextTick(() => {
        const canvas = this.$refs.bg_canvas
        const ctx = canvas.getContext("2d")
        ctx.globalAlpha = 0.3
        const width = canvas.width
        const height = canvas.height

        ctx.clearRect(0, 0, width, height)
        ctx.beginPath()
        ctx.moveTo(0, 0)
        ctx.lineTo(width / 2, 30)
        ctx.lineTo(width / 2, height - 30)
        ctx.lineTo(0, height)
        ctx.lineTo(0, 0)
        let grad = ctx.createLinearGradient(0, 0, width / 2, height / 2) // 创建一个渐变色线性对象
        grad.addColorStop(0.1, "transparent")
        grad.addColorStop(1, this.colors[0])
        ctx.fillStyle = grad // 设置fillStyle为当前的渐变对象
        ctx.fill()

        ctx.beginPath()
        ctx.moveTo(width, 0)
        ctx.lineTo(width / 2, 30)
        ctx.lineTo(width / 2, height - 30)
        ctx.lineTo(width, height)
        ctx.lineTo(width, 0)
        grad = ctx.createLinearGradient(width, 0, width / 2, height / 2) // 创建一个渐变色线性对象
        grad.addColorStop(0.1, "transparent")
        grad.addColorStop(1, this.colors[1])
        ctx.fillStyle = grad // 设置fillStyle为当前的渐变对象
        ctx.fill()
        ctx.closePath()
        const padding = this.radius[1] / 100
        const radius = this.size * padding
        this.clearArcFun(width / 2, height / 2, radius / 2 + 10, ctx)
      })
    },
    // 擦除中间圆环
    clearArcFun(x, y, r, cxt) {
      //(x,y)为要清除的圆的圆心，r为半径，cxt为context
      var stepClear = 1 //别忘记这一步
      clearArc(x, y, r)
      function clearArc(x, y, radius) {
        var calcWidth = radius - stepClear
        var calcHeight = Math.sqrt(radius * radius - calcWidth * calcWidth)
        var posX = x - calcWidth
        var posY = y - calcHeight
        var widthX = 2 * calcWidth
        var heightY = 2 * calcHeight
        if (stepClear <= radius) {
          cxt.clearRect(posX, posY, widthX, heightY)
          stepClear += 1
          clearArc(x, y, radius)
        }
      }
    },
    initPie() {
      this.$nextTick(() => {
        if (!this.myChart) {
          var chartDom = this.$refs.center_canvas
          var myChart = echarts.init(chartDom, null, {
            devicePixelRatio: window.devicePixelRatio,
          })
          this.myChart = myChart
        }
        this.myChart.setOption(this.option)
        this.myChart.resize()
      })
    },
  },
}
</script>

<style lang="less" scoped>
.zy-canvas-ring {
  position: relative;
  & > div {
    user-select: none;
  }
  &-center {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    & > div + div {
      margin-top: 6px;
    }
  }
  &-text {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-line {
      width: 4px;
      height: 24px;
      border-radius: 2px;
    }
    &-content {
      display: flex;
      flex-direction: column;
      &-title {
        font-size: 14px;
        line-height: 21px;
      }
      &-value {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        color: #ffffff;
        & > div:nth-child(1) {
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          color: #ffffff;
        }
        & > div:nth-child(2) {
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
